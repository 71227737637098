.graph-top {
    padding: 65px 0 30px;

    &__logo {
        display: block;
        text-decoration: none;

        &-slogan {
            display: inline-block;
            font-size: 44px;
            color: #af8700;
            margin: 0;
            position: relative;
            top: -10px;
        }
    }
}
.rosacal, .lipidro {
    .graph {
        s {
            color: #7a8696;
        }
    }
}
.graph {
    font-family: $second-font;
    position: relative;

    &-boxes {
        margin-top: 25px;
        &.pre-graph {
            max-height:255px;
            margin-bottom:15px;
        }
        &.pre-graph3 {
            max-height: 180px;
            margin-bottom:15px;
        }
        &.pre-graph4 {
            max-height: 255px;
        }
        &.pre-graph5 {
            max-height: 280px;
        }
        &.pre-graph6 {
            max-height: 190px;
        }
        &.pre-graph7 {
            max-height: 195px;
        }
        &.pre-graph8 {
            max-height: 215px;
        }
    }

    &-header {
        background-image: url('/assets/images/infographic/pattern.png');
        background-repeat: repeat-x;
        background-position: center;
        background-size: contain;
        padding-bottom:10px;
    }

    &-item {
        font-size: 16px;
        color: #000000;
        font-weight: 300;
        margin-bottom: 10px;

        i {
            float: left;
            display: inline-block;
            margin-right: 10px;
            margin-top: 3px;
        }

        span {
            float: left;
            width: calc(100% - 30px);
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        &-info {
            font-size:20px;
            margin: 20px 0 30px;
            border-bottom:2px solid #e2e2e2;
            padding-bottom:20px;
        }
        &-info2 {
            font-size:20px;
            margin: 0;
        }
        &-info3 {
            margin: 0px 0 30px;
            border-bottom: 2px solid #e2e2e2;
            padding-bottom: 20px;
        }
        &-info4 {
            font-size:20px;
            margin: 20px 0 20px;
            border-bottom:2px solid #e2e2e2;
            padding-bottom:20px;
        }
    }

    &-title {
        font-family: $main-font;

        span {
            display: inline-block;
            background-color: #af8700;
            font-size: 24px;
            color: #ffffff;
            padding: 12px 23px;
            margin-top: 13px;
            max-width:66%;
        }
    }

    &-face {
        i {
            margin: 0 auto;
            position: relative;
            display:block;
            top: -210px;
            width:100%;
            height:307px;
            background-size:contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &-photo-1 {
        position: relative;
        top: -83px;

        img {
            width: 100%;
        }

        &.extra-top {
            top:-185px;
        }
    }

    &-photo-2 {
        position: relative;
        top: -125px;

        img {
            width: 100%;
        }

        &.extra-top2 {
            top:-138px;
        }
    }

    &-photo-3 {
        img {
            width: 100%;
        }
    }

    em {
        color: #af8700;
        font-style: normal;
    }

    s {
        color: #7a8696;
        font-style: normal;
        text-decoration: none;
    }

    &-three {
        font-size: 14px;
        color: #000000;
        border: 1px solid #000000;

        .black {
            background-color: #000000;
            color: #ffffff;
        }
        .border-bottom {
            border-bottom: 1px solid #000000;
        }
        .border {
            border-right: 1px solid #000000;
        }
        .columns.is-gapless:not(:last-child){
            margin-bottom:0 !important;
        }
        .columns.is-gapless>.column {
            padding: 20px !important;
        }
    }

    &-three-title {
        i {
            float: left;
        }
    }

    &-three-text {
        margin: 15px 0;
        small {
            padding: 1px 2px;
            font-size:14px;
        }
    }

    &-three-link {
        i {
            float: left;
            margin: 3px 10px 10px 0;
        }
    }

    &-two {

        padding-bottom: 10px;
        margin-bottom: 25px;
        border-bottom:2px solid #e2e2e2;

        &.last {
            border-bottom:0;
            padding-bottom: 15px;
            margin-bottom: 0; 
        }

        ul {
            margin-top: 25px;

            li {
                font-size: 16px;
                font-weight: 300;
                color: #000000;
                list-style: none;
                @include clearfix;
                margin-bottom: 12px;

                i {
                    float: left;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 7px;
                        height: 7px;
                        margin-right: 10px;
                        background-color: #af8700;
                    }
                }

                span {
                    width: calc(100% - 20px);
                    float: left;
                }
            }
        }

        &-title {
            font-size: 24px;
            line-height: 28px;

            span {
                font-size: 14px;
            }
        }

        .border {
            border-right: 5px solid #af8700;
            margin-right:10px;
        }
    }

    .borders-last {
        position: relative;
        top: 15px;
        border-top: 2.5px solid #e2e2e2;
        margin: 0 15px;
    }

    .borders-last2 {
        position: relative;
        border-top: 2.5px solid #e2e2e2;
        margin: 15px 15px;
        padding:0;
    }

    .borders-last3 {
        position: relative;
        top: 0px;
        border-top: 2.5px solid #e2e2e2;
        margin: 15px 15px 15px;
        padding:0;
    }
    .borders-last4 {
        position: relative;
        top: 10px;
        border-top: 2.5px solid #e2e2e2;
        margin: 10px 15px 0;
    }
    .borders-last5 {
        position: relative;
        top: 10px;
        border-top: 2.5px solid #e2e2e2;
        margin: 0 15px;
    }
    .borders-last6 {
        position: relative;
        top: 15px;
        border-top: 2.5px solid #e2e2e2;
        margin: 0 15px;
    }
    .borders-last7 {
        position: relative;
        top:0;
        border-top: 2.5px solid #e2e2e2;
        margin: 10px 15px 25px;
    }

    .graph-skin40-borders {
        position: relative;
        top: 0px;
        border-top: 2.5px solid #e2e2e2;
        margin: 0;
        padding:0;
        margin-left:15px;
    }

    &-buy {
        font-size: 24px;
        color: #000000;
        text-align: center;
        margin-top:60px;
        i {
            margin: 0 auto 10px;
        }

        a {
            max-width: 140px;
            display: block;
            background-color: #000000;
            color: #ffffff;
            font-size: 16px;
            padding: 14px 0;
            margin: 10px auto 0;
            text-decoration: none;
        }
    }

    .skewed-graph {
        transform: rotate(-2deg) skew(-2deg);
        width: 100%;
        position: absolute;
        bottom: -45px;
        height: 90px;
        left: 0;
        z-index: 100;
        background-color: #fff;
    }
    .sheight {
        max-height:60px;
    }
    .sheight2 {
        max-height:130px;
    }
    .sheight3 {
        max-height:110px;
    }
    .sheight4 {
        max-height:120px;
    }
    .sheight5 {
        max-height:140px;
    }
    .sheight6 {
        max-height: 265px;
    }
    &-gold {
        border: 1px solid $gold;
        font-size: 16px;
        line-height:28px;
        font-weight: 300;
        color: $gold;
        margin: 30px 0;
        font-family: $second-font;
        position: relative;
        z-index: 1000;
        i {
            font-family: $main-font;
            font-size: 36px;
            float: left;
            line-height: 36px;
            font-style: normal;
            border-right: 1px solid $gold;
            padding-right: 16px;
            height: 45px;
            margin:6px 23px 15px 6px;
        }
    }

    &-special {
        background-color: #458694;
        color:#fff;
        padding:0 5px;
        font-weight:400;
    }
    &-special-one {
        padding:14px 0 10px;
        display:inline-block;
    }
    .noborder {
        border:0;
    }
}

@media (max-width: 1230px) {
    .graph .sheight6 {
        max-height:100%;
    }
}
@media (max-width: 992px) {
    .graph {
        &-buy {
            margin-top:0;
        }
        &-item {
            font-size: 14px;

            i {
                margin-top: 2px;
            }
        }

        &-face {
            i {
                background-size: contain;
                height: 250px;
                width: 100%;
            }
        }

        &-two {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }

        &-gold {
            padding:24px 20px 20px 20px;
            i {
                margin:0;
                padding:0;
                border:0;
                float:none;
                display:block;
                margin-bottom:15px;
            }
            span {
                display:block;
                padding-top:15px;
                border-top:1px solid $gold;
                border-left:0;
                font-size:14px;
            }
        }
    }
}

@media (max-width: 768px) {
    .graph {
        &-top {
            padding:30px 0 10px;
        }
        .mob-hid {
            display:none;
        }
        &-item {
            &-info {
                margin:0;
            }
            &-info3 {
                margin:0;
                padding:0;
            }
        }
        .nobotmar {
            margin-bottom:0;
        }
        .notopmar {
            margin-top:0;
        }
        .nobotpad {
            padding-bottom:0;
        }
        .notoppad {
            padding-top:0;
        }
        .pre-graph,
        .pre-graph3, 
        .pre-graph4, 
        .pre-graph5,
        .pre-graph6,
        .pre-graph7,
        .pre-graph8,
        .sheight, 
        .sheight2,
        .sheight3,
        .sheight4,
        .sheight5,
        .sheight6 {
            max-height:100%;
        }
        &-three {
            &-title {
                font-size:14px;
            }
            .border {
                border-right: 0;
            }
        }
        .skewed-graph {
            height:60px;
        }
        &-header {
            height:auto;
            padding-bottom:10px;
        }
        &-gold {
            margin:15px 0;
        }
        &-photo-1, 
        &-photo-2, 
        &-photo-1.extra-top,
        &-photo-2.extra-top2, {
            top:0;
        }
        &-two {
            margin-bottom:0;
            ul {
                padding-left:0;
                margin-top:15px;
            }
            &-title {
                font-size:18px;
            }
            .border {
                border-right: 0;
            }
        }

        &-face {
            i {
                background-position: center;
                top: 0;
            }
        }
        &-title {
            span {
                font-size:20px;
            }
        }
        .borders-last6 {
           padding:0;
           margin:0 15px;
        }
        .borders-last7 {
           padding:0;
           margin:0 15px;
           top:-15px;
        }
        .graph-item-info4 {
            margin-bottom:0;
        }
    }
}