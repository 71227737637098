$pyshio: #718696;
$sebio: #498692;
$strefa: #679882;
$hydrativ: #4c6d9f;
$lipidro: #ac8700;
$rosacal: #a3717e;
$futuris: #81913e;
$provivo: #44647f;
$relift: #ae3c4e;
$stimular: #d95b14;
$modelar: #795e97;