.physio .hydrative,
.physio .rosacal,
.physio .skin45,
.physio .skinmixed,
.physio .skinimper,
.physio .lipidro,
.physio .skin50,
.physio .skin45,
.physio .skin45,
.physio, {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $pyshio;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $pyshio;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$pyshio;
    }
    .graph-gold {
        color:$pyshio;
        border:1px solid $pyshio;
    }
    .article-ps {
        border-color:$pyshio;
        background-color: $pyshio;
    }
    .graph-gold i {
        border-color:$pyshio;
        color:$pyshio;
    }
    .graph-three-text small {
        background-color:$pyshio;
        color:$white;
    }
}

.sebio .hydrative,
.sebio .rosacal,
.sebio .skin45,
.sebio .skinmixed,
.sebio .skinimper,
.sebio .lipidro,
.sebio .skin50,
.sebio .skin45,
.sebio .skin30,
.sebio {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $sebio;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $sebio;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$sebio;
    }
    .graph-gold {
        color:$sebio;
        border:1px solid $sebio;
    }
    .article-ps {
        border-color:$sebio;
        background-color: $sebio;
    }
    .graph-gold i {
        border-color:$sebio;
        color:$sebio;
    }
    .graph-three-text small {
        background-color:$sebio;
        color:$white;
    }
}

.strefa .hydrative,
.strefa .rosacal,
.strefa .skin45,
.strefa .skinmixed,
.strefa .skinimper,
.strefa .lipidro,
.strefa .skin50,
.strefa .skin45,
.strefa .skin30,
.strefa {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $strefa;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $strefa;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$strefa;
    }
    .graph-gold {
        color:$strefa;
        border:1px solid $strefa;
    }
    .article-ps {
        border-color:$strefa;
        background-color: $strefa;
    }
    .graph-gold i {
        border-color:$strefa;
        color:$strefa;
    }
    .graph-three-text small {
        background-color:$strefa;
        color:$white;
    }
}


.hydrativ .hydrative,
.hydrativ .rosacal,
.hydrativ .skin45,
.hydrativ .skinmixed,
.hydrativ .skinimper,
.hydrativ .lipidro,
.hydrativ .skin50,
.hydrativ .skin45,
.hydrativ .skin30,
.hydrativ {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $hydrativ;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $hydrativ;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$hydrativ;
    }
    .graph-gold {
        color:$hydrativ;
        border:1px solid $hydrativ;
    }
    .article-ps {
        border-color:$hydrativ;
        background-color: $hydrativ;
    }
    .graph-gold i {
        border-color:$hydrativ;
        color:$hydrativ;
    }
    .graph-three-text small {
        background-color:$hydrativ;
        color:$white;
    }
}

.lipidro .hydrative,
.lipidro .rosacal,
.lipidro .skin45,
.lipidro .skinmixed,
.lipidro .skinimper,
.lipidro .lipidro,
.lipidro .skin50,
.lipidro .skin45,
.lipidro .skin30,
.lipidro {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $lipidro;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $lipidro;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$lipidro;
    }
    .graph-gold {
        color:$lipidro;
        border:1px solid $lipidro;
    }
    .article-ps {
        border-color:$lipidro;
        background-color: $lipidro;
    }
    .graph-gold i {
        border-color:$lipidro;
        color:$lipidro;
    }
    .graph-three-text small {
        background-color:$lipidro;
        color:$white;
    }
}

.rosacal .hydrative,
.rosacal .rosacal,
.rosacal .skin45,
.rosacal .skinmixed,
.rosacal .skinimper,
.rosacal .lipidro,
.rosacal .skin50,
.rosacal .skin45,
.rosacal .skin30,
.rosacal {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $rosacal;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $rosacal;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$rosacal;
    }
    .graph-gold {
        color:$rosacal;
        border:1px solid $rosacal;
    }
    .article-ps {
        border-color:$rosacal;
        background-color: $rosacal;
    }
    .graph-gold i {
        border-color:$rosacal;
        color:$rosacal;
    }
    .graph-three-text small {
        background-color:$rosacal;
        color:$white;
    }
}

.futuris .hydrative,
.futuris .rosacal,
.futuris .skin45,
.futuris .skinmixed,
.futuris .skinimper,
.futuris .lipidro,
.futuris .skin50,
.futuris .skin45,
.futuris .skin30,
.futuris {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $futuris;
    }
    .graph-gold span,
    .graph-special-one,
  .graph-item-info,.graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $futuris;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$futuris;
    }
    .graph-gold {
        color:$futuris;
        border:1px solid $futuris;
    }
    .article-ps {
        border-color:$futuris;
        background-color: $futuris;
    }
    .graph-gold i {
        border-color:$futuris;
        color:$futuris;
    }
    .graph-three-text small {
        background-color:$futuris;
        color:$white;
    }
}

.provivo .hydrative,
.provivo .rosacal,
.provivo .skin45,
.provivo .skinmixed,
.provivo .skinimper,
.provivo .lipidro,
.provivo .skin50,
.provivo .skin45,
.provivo .skin30,
.provivo {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $provivo;
    }
    .graph-gold span,
    .graph-special-one,
    .graph-item-info,
    .graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $provivo;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$provivo;
    }
    .graph-gold {
        color:$provivo;
        border:1px solid $provivo;
    }
    .article-ps {
        border-color:$provivo;
        background-color: $provivo;
    }
    .graph-gold i {
        border-color:$provivo;
        color:$provivo;
    }
    .graph-three-text small {
        background-color:$provivo;
        color:$white;
    }
}

.relift .hydrative,
.relift .rosacal,
.relift .skin45,
.relift .skinmixed,
.relift .skinimper,
.relift .lipidro,
.relift .skin50,
.relift .skin45,
.relift .skin30,
.relift {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $relift;
    }
    .graph-gold span,
    .graph-special-one,
  .graph-item-info,.graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $relift;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$relift;
    }
    .graph-gold {
        color:$relift;
        border:1px solid $relift;
    }
    .article-ps {
        border-color:$relift;
        background-color: $relift;
    }
    .graph-gold i {
        border-color:$relift;
        color:$relift;
    }
    .graph-three-text small {
        background-color:$relift;
        color:$white;
    }
}

.stimular .hydrative,
.stimular .rosacal,
.stimular .skin45,
.stimular .skinmixed,
.stimular .skinimper,
.stimular .lipidro,
.stimular .skin50,
.stimular .skin45,
.stimular .skin30,
.stimular {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $stimular;
    }
    .graph-gold span,
    .graph-special-one,
  .graph-item-info,.graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $stimular;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$stimular;
    }
    .graph-gold {
        color:$stimular;
        border:1px solid $stimular;
    }
    .article-ps {
        border-color:$stimular;
        background-color: $stimular;
    }
    .graph-gold i {
        border-color:$stimular;
        color:$stimular;
    }
    .graph-three-text small {
        background-color:$stimular;
        color:$white;
    }
}

.modelar .hydrative,
.modelar .rosacal,
.modelar .skin45,
.modelar .skinmixed,
.modelar .skinimper,
.modelar .lipidro,
.modelar .skin50,
.modelar .skin45,
.modelar .skin30,
.modelar {
    .graph-special,
    .graph-two ul li i:before,
    .graph-title span,
    .article-banner__text p,
    .article-banner__text span {
        background-color: $modelar;
    }
    
    .graph-special-one,
  .graph-item-info,.graph-item-info2,
    .graph s, 
    .graph em,
    .article-info__title span,
    .graph-top__logo-slogan {
        color: $modelar;
    }
    .graph-gold span,
    .article-template .with-square p,
    .article-template .with-border p,
    .graph-two .border,
    .article-box__content {
        border-color:$modelar;
    }
    .graph-gold {
        color:$modelar;
        border:1px solid $modelar;
    }
    .article-ps {
        border-color:$modelar;
        background-color: $modelar;
    }
    .graph-gold i {
        border-color:$modelar;
        color:$modelar;
    }
    .graph-three-text small {
        background-color:$modelar;
        color:$white;
    }
}