@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) {
    .bulb {
        background-image:url('/assets/images/retina/bulb.png');
        background-repeat:no-repeat!important;
        background-size:56px 56px!important;
        width:56px!important;
        height:56px!important
    }
    .icon-facebook {
        background-image:url('/assets/images/retina/facebook.png');
        background-repeat:no-repeat!important;
        background-size:51px 51px!important;
        width:51px!important;
        height:51px!important
    }
    .icon-instagram {
        background-image:url('/assets/images/retina/instagram.png');
        background-repeat:no-repeat!important;
        background-size:51px 51px!important;
        width:51px!important;
        height:51px!important
    }
    .tolpa-you {
        background-image:url('/assets/images/retina/tolpa-you.png');
        background-repeat:no-repeat!important;
        background-size:51px 51px!important;
        width:85px!important;
        height:85px!important
    }
    .tolpa-circle {
        background-image:url('/assets/images/retina/tolpa-quiz.png');
        background-repeat:no-repeat!important;
        background-size:51px 51px!important;
        width:85px!important;
        height:85px!important
    }
    .top {
        &__logo {
            background-image: url('/assets/images/retina/logo.png');
            background-repeat:no-repeat!important;
            background-size:108px 35px !important;
            width:108px!important; 
            height:35px!important;
        }
    }

    @media (max-width: 992px) {
        .top {
            &__logo {
                background-image: url('/assets/images/retina/logo.png');
                background-repeat:no-repeat!important;
                background-size:83px 24px !important;
                width:83px!important;
                height:24px!important 
            }
        }
    }
    .info__box.first i {
        background-image: url('/assets/images/retina/day.png');
        background-repeat:no-repeat!important;
        background-size:85px 85px!important;
        width:85px!important;
        height:85px!important
    }
    .info__box.second i {
        background-image: url('/assets/images/retina/night.png');
        background-repeat:no-repeat!important;
        background-size:85px 85px!important;
        width:85px!important;
        height:85px!important
    }
    .info__box.third i {
        background-image: url('/assets/images/retina/eye.png');
        background-repeat:no-repeat!important;
        background-size:85px 85px!important;
        width:85px!important;
        height:85px!important
    }
    .banner__more {
        background-image: url('/assets/images/retina/scroll-down.png');
        background-repeat:no-repeat!important;
        background-size:33px 67px!important;
        width:33px!important;
        height:67px!important
    }
    @media (max-width: 992px){
        .banner__more {
            width: 50px !important;
            height: 21px !important;
            background-size:50px 21px!important;
            background-image: url('/assets/images/retina/indi-mob.png');
        }
    }
}