$min-vw: 320px;
$max-vw: 1920px;


$main-font: 'LinoType';
$second-font: 'Open Sans', sans-serif;

$black: #010101;
$black2: #231f20;
$white: #ffffff;
$sea: #498692;
$sea2: #7a8696;
$grey: #cccccc;
$grey2: #e6e7e8;
$grey-bg-quiz: #ebeaed;
$grey3: #6b6c6e;
$grey4: #939598;
$grey5: #58595b;
$blue: #b0b6bf;
$gold: #af8700;