.footer {           
    padding:43px 0 70px; 
    background-color:$grey2;
    &__copyright {
        color:$grey5;
        &-socials {
            margin-right:47px;
        }
        &-link {
            color:$grey5;
            font-size:18px;
            text-decoration: none;
            display:inline-block;
            vertical-align: top;
            margin-top: 25px;
            @include transition;
            font-family: $second-font;
            span {
                border-bottom:1px solid transparent;
            }
            &:hover span {
                border-bottom:1px solid $grey5;
            }
        }
        small {
            color:$grey5;
            font-size:18px;
            float:right;
            margin-top: 25px;
            font-family: $second-font;
        }
    }
    &__info {
        &-info {
            font-size:30px;
            color:$grey5;
            margin-bottom:50px;
            font-family: $second-font;
            small {
                font-size:120px;
                color:$black;
                float:left;
                margin-right: 33px;
                margin-top: -15px;
                font-family: $main-font;
            }
            span {
                margin-top:5px;
                display:block;
                font-size:18px;
            }
        }
    }
}

@media (max-width: 992px) {
    .footer {
        padding-bottom:20px;
        &__copyright {
            text-align:center;
            &-container {
                text-align:center;
            }
            &-socials {
                margin:0;
                width: 50%;
                display: block;
                float: left;
                i {
                    margin:0 5px;
                }
                &:nth-child(1) {
                    text-align: right;
                }
                &:nth-child(2) {
                    text-align: left;
                }
            }
            &-link {
                font-size:12px;
            }
            small {
                width:100%;
                text-align:center;
                font-size:12px;
                margin-top:10px;
            }
        }
        &__info {
            &-info {
                text-align: center;
                font-size:16px;
                margin-bottom:35px;
                span {
                    margin-top:15px;
                    font-size:14px;
                }
                small {
                    font-size:60px;
                    width:100%;
                    margin-right:0;
                    margin-bottom:20px;
                }
            }
        }
    }
}