.top {
    position:absolute;
    width:100%;
    z-index:1;
    padding-top:50px;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
    padding-bottom:10vh;
    &__logo {
        background-image: url('/assets/images/logo.png');
        background-position: top left;
        background-size: contain;
        background-repeat: no-repeat;
        width:108px;
        height:35px;
        display:block;
        float:left;
        margin-right:12px;
    }
    &__slogan {
        
        font-size: 32px;
        color:$white;
        float:left;
        margin-top:-4px;
        display: block;
        text-decoration: none;
        span {
            display:inline-block;
        }
        a {
            color:$white;
            text-decoration: none;
        }
    }
    &__nav {
        float:right;
        ul {
            list-style: none;
            margin:0;
            padding:0;
            li {
                display:inline-block;
                padding:0;
                margin:0;
                margin-top:10px;
                a {
                    font-family:$second-font;
                    color: #eee;
                    @include fluid-type($min-vw, $max-vw, 18px, 24px);
                    color:$grey;
                    text-decoration: none;
                    padding:5px 15px;
                    margin-left:15px;
                    color:#fff;
                    @include transition ();
                    &.active {
                        background-color:$black;
                    }
                    &:hover {
                        background-color:$black;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .top {
        &__logo {
            margin-right:0;
            width:83px; 
        }
        &__nav {
            display:none;
        } 
        &__slogan {
            margin-top: -5px;
            font-size: 24px;
            span {
                position:relative;
                top:3px;
                width:18px;
            }
        }
    }
}


