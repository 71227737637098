.quiz {

    /*    &:after{
            position: absolute;
            content: "";
            width: 200px;
            height: 100%;
            left: 1220px;
            top: 0;
            z-index: 99;
            background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(235,234,237,1) 100%);  FF3.6-15 
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(235,234,237,1) 100%);  Chrome10-25,Safari5.1-6 
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(235,234,237,1) 100%);  W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ebeaed',GradientType=1 );  IE6-9 
        }*/

    background-color: $grey-bg-quiz;
    min-height: 90vh; 
    position: relative; 
    padding: 70px 0;
    box-sizing: border-box;
    /*    background-image: url('/assets/images/quiz-photo.jpg');
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        background-size: auto 100%;*/ 
    overflow: hidden;

    &__photo {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
    }

    &__content {
        position: relative;
        width: 58%;
        float: right;
        text-align: left;
        z-index: 100;

        &.email {


            .quiz__questions {
                margin-top: 121px;
            }

            .quiz__subject {
                @include clearfix;

                span {
                    float: left;

                }
            }
        }
    }

    &__title {
        color: $black2;
        font-size: 27px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__subtitle {
        color: $black2;
        font-size: 18px;
        font-family: $second-font;
    }

    &__questions {
        margin-top: 80px;
        ul {
            margin: 0;
            padding: 0;
            margin-top: 21px;
            @include clearfix;
            &.disabled {
                @include transition;
                pointer-events: none;
            }

        }

        p {
            &.hide {
                display: none;
            }
            &.error {
                color:#ff1947;
                font-size: 12px;
                text-align: center;
                margin-top:5px;
            }
        } 

    }

    &__question {
        opacity: 0;
        list-style: none;
        font-family: $second-font;
        font-size: 18px;
        color: $white;
        padding: 13px 20px 13px 20px;
        background-color: $blue;
        border-radius: 5px;
        margin-bottom: 5px;
        display: block;
        display: inline-block;
        float: left;
        margin-right: 5px;
        &--active{
            opacity: 1;
            @include transition(all, 0.1s);
        }
        @include transition;

        &:hover {
            cursor: pointer;
            background-color: $sea2;
        }



    }

    &__subject {
        position: relative;

        span {
            border-radius: 5px;
            background-color: $sea2;
            font-family: $second-font;
            font-size: 18px;
            color: $white;
            padding: 16px 20px 16px 20px;
        }

        i {
            position: absolute;
            top: -15px;
            left: -100px;
        }
    }

    &__answer {
        text-align: right;
        position: relative;
        float: right;
        border-radius: 5px;
        background-color: $sea;
        padding: 16px 20px 16px 20px;
        margin-right: 20px;
        font-family: $second-font;
        font-size: 18px;
        color: $white;
        margin-right: 100px;
        margin-top: 20px;
        @include transition;

        i {
            position: absolute;
            top: 5px;
            right: -100px;

        }

        &.hidden {
            opacity: 0;
        }
    }

    &__video {
        position: absolute;
        z-index: 10;
        transform: translateY(-50%);
        top: 50%;
        left: 0;

        width: 100%;
        height: auto;
        @media screen and (max-width: 1400px){
            width: 100vw;
            min-height: 100%;    
        }

        @include transition(all, 0.5s);
        &--muted{
            opacity: 0;
            @include transition(all, 0.5s);
        }
    }



    &__mail {
        margin-top: 33px;
        //margin-bottom: 20px;
        background-color: #98b6bd;
        padding: 23px 22px;
        border-radius:5px 5px 0 0;
    }

    &__user {
        width: 100%;
        height: 50px;
        padding: 0 10px;
        box-sizing: border-box;
        outline: none;
        font-family: $second-font;
    }

    &__checkbox {
        background-color: #98b6bd;
        padding:0 22px 23px;
        border-radius:0 0 5px 5px;

        label {
            font-size: 12px;
            color: #fff;
            position: relative;
            padding-left:25px;
            display: block;
            position: relative;
            z-index: 1;
            font-family: "Open Sans", sans-serif;

            .hide {
                height: 0;
                opacity:0;
                display: block;
                @include transition;

                &.show {
                    height: auto;
                    opacity:1;
                    display: inline-block;
                }
            }



            a {
                color:#fff;
            }

            input {
                position: absolute;
                left: 0;
                top: 3px;
            }

        }

        .read-more {
            cursor: pointer;
            font-size: 12px;
            color: #fff;
            position: relative;
            z-index: 2;
            margin:-13px 0 0 25px;
            display: inline-block;
        }


    }

    &__label {
        font-size: 18px;
        color: $white;
        padding-bottom: 10px;
    }

    &-results {
        position: relative;
        z-index: 100;
        margin-top: 65px;

        &__content {
            display: inline-block;
        }

        &__title {
            font-size: 36px;
            color: $black2;
            margin-bottom: 0px;
            text-align: center;
        }

        &__slogan {
            font-family: $second-font;
            font-size: 17px;
            color: $black2;
            margin-bottom: 20px;

            span {
                display: block;
                margin-top: 25px;
                &.on-black{
                    display: inline-block;
                    background-color: black;
                    color: white;
                    padding: 0 5px;
                }
            }
        }

        &__product {
            text-align: left;
            width: 27.3%;
            float: left;
            padding: 0 3%;
            text-decoration: none;
            color: $black2;

            img {
                width: 50%;
            }

            &__gift{
                position: absolute;
                z-index: -1;
                top: 0;
                right: 28%;
            }
        }

        &__info {
            font-family: $second-font;
            line-height: 21px;
            font-size: 15px;
            margin-top:11px;

        }

        &__day {
            position: relative;
            top: -45px;
            text-align: left;

            span {
                font-size: 24px;
                color: $grey4;
                font-family: $second-font;
                display: block;
            }

            font-size:30px;
        }

        &__photo {
            text-align: right;
            position: relative;
            @include clearfix;
            margin-top: 10px;

            i {
                background-size: contain !important;
                bottom: 0 !important;
                position: static !important;
                margin: 0 0 -100px 0 !important;
                height: 90px;
                width: 90px;
                background-position: center center;
            }
        }

        &__link {
            font-size: 24px;
            color: $white;
            background-color: $black;
            display: inline-block;
            text-decoration: none;
            padding: 11px 30px 11px 30px;
            margin-top: 0px;
            box-sizing: border-box;
            position: relative;
            width: 295px;
            margin-bottom:60px;

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 35px;
            }
        }
    }

    #show-results {
        position: relative;
        z-index: 11;
    }
}

@media (min-width: 992px) and (max-width: 1350px) {
    .quiz {
        &__video {
            transform: translate(-50%, -50%);
            left: 50%;
            object-fit: cover;
        }
    }
}

@media (max-width: 992px) {
    .quiz {
        background-image: none;
        padding: 20px 0 10vh;
        min-height: auto;

        &__label {
            font-size:14px;
        }
        &__mail {
            padding:17px 15px;
            margin-bottom:0;
        }

        &__content {
            width: 100%;
        }

        &__video {
            display: none;
        }

        &__title {
            text-align: center;
            font-size:18px;
            padding-top:69px;
        }

        &__subtitle {
            text-align: center;
            font-size: 14px;
        }

        &__questions {
            margin-top: 100px;
            @include calc(width, '100% - 60px');
            padding-left: 60px;
            margin-top:57px !important;
            ul {
                margin-top:2px;
            }
        }



        &__question {
            font-size: 14px;
            padding:10px 8px;
            margin-bottom:10px;
            margin-right:10px;
        }
        &__subject {
            margin-bottom:10px;
            span {
                font-size: 14px;
                padding:10px 8px;
                display:inline-block;
            }
            i {
                top: -25px;
                left: -80px;
            }
        }
        &__answer {
            font-size: 14px;
            margin-top: 5px;
            float: left;
            padding:10px 8px;
            span {
                display: block;
            }
            i {
                top: -20px;
                right: -70px;
            }
        }

        &-results {
            &__photo {
                text-align: left;

                img {
                    float: left;
                    display: block;
                    width: 25vw;
                }

                i {
                    background-size: 25%;
                    margin-left: 200px;
                    bottom: 140px;
                    display: none;
                }
            }

            &__link {
                margin-top: 15px;
                margin-bottom:0px;
            }

            &__day {
                font-size: 14px;
                color: $grey4;
                float: left;
                position: static;
                top: 0;
                @include calc(width, '100% - 25vw');


                span {
                    color: $black2;
                    font-size: 16px;
                }
            }

            &__content {
                padding-top:20px;
                @include clearfix();
            }


            &__product {
                width: 100%;
                padding: 0;
                margin-bottom: 35px;
                &__gift {
                    display: none !important;
                }
            }

            &__info {
                font-size: 13px;
                margin-top: 10px;
                color: #000;
            }

            &__title {
                font-size: 18px;
            }

            &__slogan {
                font-size: 12px;
            }

            &__link {
                width: auto;
                font-size: 15px;
                padding-left: 7px;
                padding: 16px 50px 16px 35px;
                float: right;

                i {
                    width: 7px;
                    height: 11px;
                }
            }
        }

        #show-results {
            width: 100%; 
            padding: 1em;
            text-align: center;
            margin-left:0;
            margin-bottom:39px;

            i {
                position: static;
                margin-left:10px;
                transform:translateY(0);
                margin-left:10px;
            }
        }
    }
}

@media (max-width: 490px) {
    .quiz {
        &__questions {
            // width: 65%;
            // padding-left: 25%;
        }

        &__answer {
            //  left: -45px;
        }
    }
}