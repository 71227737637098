.bulb {
    background-image:url('/assets/images/bulb.png');
    background-repeat: no-repeat;
    background-position: center;
    display:block;
    width:56px;
    height:56px;
}
.arrow-right {
    background-image:url('/assets/images/arrow-right.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    width:13px;
    height:23px;
    display:inline-block;
}
.icon-facebook {
    background-image:url('/assets/images/facebook.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width:51px;
    height:51px;
    display:inline-block;
}
.icon-instagram {
    background-image:url('/assets/images/instagram.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width:51px;
    height:51px;
    display:inline-block;
}
.tolpa-you {
    background-image:url('/assets/images/tolpa-you.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width:85px;
    height:85px;
    display:inline-block;
}
.tolpa-circle {
    background-image:url('/assets/images/tolpa-circle.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width:85px;
    height:85px;
    display:inline-block;
}

.arrow-black {
    background-image:url('/assets/images/infographic/arrow-black.png');
    background-repeat: no-repeat;
    background-position: center;
    display:block;
    width:18px;
    height:18px;
}
.arrow-white {
    background-image:url('/assets/images/infographic/arrow-white.png');
    background-repeat: no-repeat;
    background-position: center;
    display:block;
    width:18px;
    height:18px;
}
.icon-drop {
    background-image:url('/assets/images/infographic/drop.png');
    background-repeat: no-repeat;
    display:block;
    width:32px;
    height:23px;
}
.icon-sun {
    background-image:url('/assets/images/infographic/sun.png');
    background-repeat: no-repeat;
    display:block;
    width:32px;
    height:23px;
}
.icon-moon {
    background-image:url('/assets/images/infographic/moon.png');
    background-repeat: no-repeat;
    display:block;
    width:32px;
    height:23px;
}
.icon-tonise {
    background-image:url('/assets/images/infographic/retina/tonise.png');
    background-repeat: no-repeat;
    background-size:100%;
    display:block;
    width:14px;
    height:28px;
    margin-right:15px;
}
.icon-ps {
    background-image:url('/assets/images/infographic/ps.png');
    background-repeat: no-repeat;
    display:block;
    width:51px;
    height:48px;
}
.icon-bag {
    background-image:url('/assets/images/infographic/bag.png');
    background-repeat: no-repeat;
    display:block;
    width:39px;
    height:37px;
}

.light-day {
    background-image:url('/assets/images/product-icon-day.png');
    background-repeat: no-repeat;
    display:block;
    width:120px;
    height:120px;
}
.light-night {
    background-image:url('/assets/images/product-icon-night.png');
    background-repeat: no-repeat;
    display:block;
    width:120px;
    height:120px;
}

.light-eye {
    background-image:url('/assets/images/product-icon-eye.png');
    background-repeat: no-repeat;
    display:block;
    width:137px;
    height:91px;
}

@media (max-width: 992px) {
    .arrow-right {
        width:7px;
        height:11px;
    }
    .tolpa-circle, .tolpa-you {
        background-size:50%;
    }
}