.article-box {
    padding:75px 0 35px;
    position:relative;
    &__content {
        border-top:4px solid #af8700;
        border-bottom:4px solid #af8700;
        @include clearfix();
        @include flexbox();
        @include align-items(center);
        position:relative;
    }
    &__photo {
        float:left;
        margin-right:21px;
        img {
            display:block;
        }
    }
    &__text {
        font-size:16px;
        padding:37px 0 0 0;
        font-family: $second-font;
    }
    a {
        font-size:24px;
        color:$white;
        display:block;
        background-color:$black;
        padding:11px 40px 13px 20px;
        box-sizing: border-box;
        text-decoration: none;
        position: relative;
        float: right;
        margin-top:15px;
        i {
            background-image:url('/assets/images/arrow-right.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center right;
            width:13px;
            height:23px;
            display:inline-block;
            position:absolute;
            transform: translateY(-50%);
            top: 50%;
            right:15px;
        }
    }
    .skewed-article-box {
        transform: rotate(-2deg) skew(-2deg);
        width: 100%;
        position: absolute;
        top: -45px;
        height: 90px;
        left: 0;
        z-index: 100;
        background-color: #fff;
    }
}

@media (max-width: 992px) {
    .article-box {
        padding-top:20px;
       
        &__text {
            font-size:14px;
        }
        &__photo {
            margin-bottom:14px;
            width:100%;
            img {
                width:100%;
            }
        }
        a {
            font-size: 15px;
            padding-left:7px;
            padding:11px 30px 13px 7px;
            position:relative;
            bottom:auto;
            margin:10px 0 20px;
            i {
                width:7px;
                height:11px;
            }
        }
        .skewed-article-box {
            height:55px;
        }
    }
}
@media (min-width: 992px) and (max-width: 1100px) {
    .article-box {
        &__text {
            padding-top:10px;
        }
    }
}

@media (max-width: 768px) {
    .article-box {
        &__content {
         @include flex-direction(column);
        }
        
        &__photo {
          margin:0;
        }
    }
}        