.banner {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width:100%;
    height:100vh;
    position:relative;
    overflow: hidden;
    &__set {
        display:block;
        float:left;
        margin-top:280px;
        position:relative;
        transition: all 0.2s;
        &:hover {
            transform: scale(1.2);
        }
    }
    &__text {
        position:absolute;
        width:40%;
        top: calc(100vh - 290px);
        right:10px;
        @include fluid-type($min-vw, $max-vw, 18px, 34px);
        color:$white;
        span {
            display:inline-block;
            background-color:$sea;
            padding:0px 30px 4px 23px;
            margin-bottom:5px;
        }
        a {
            @include fluid-type($min-vw, $max-vw, 16px, 24px);
            color:$white;
            display:inline-block;
            background-color:$black;
            padding:11px 40px 13px 20px;
            margin-bottom:5px;
            box-sizing: border-box;
            text-decoration: none;
            margin-top:37px;
            position:relative;
            i {
                background-image:url('/assets/images/arrow-right.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center right;
                width:13px;
                height:23px;
                display:inline-block;
                position:absolute;
                transform: translateY(-50%);
                top: 50%;
                right:15px;
            }
        }
    }
    &__more {
        position:absolute;
        bottom:24px;
        background-image:url('/assets/images/indicator.png');
        background-size: cover;
        width:33px;
        height:67px;
        left:49%;
        transform: translateX(-50%);
        &:hover {
            cursor:pointer;
        }
    }
    &__video {
        &:before{
            content: "";
            width: 50vh;
            height: 100%;
            background: -moz-linear-gradient(left, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
        }

        position: absolute;
        width: auto;
        height: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

    }
}

@media (max-width: 992px) {
    .banner {
        /*height:640px;*/
        &__text {
            width:calc(100% - 32px);
            left:16px;
            @include fluid-type($min-vw, $max-vw, 14px, 18px);
            bottom:70px;
            top: auto;
            span {
                padding:4px 30px 4px 7px;
                margin-bottom:2px;
            }
            a {
                @include fluid-type($min-vw, $max-vw,12px, 15px);
                padding-left:7px;
                padding:11px 30px 13px 7px;
                i {
                    width:7px;
                    height:11px;
                }
            }
        }
        &__set {
            float:right;
            margin-top:123px;
            img {
                width:125px;
                height:125px;
            }
        }
        &__more {
            width:50px;
            height:21px;
            left:43%;
            background-image:url('/assets/images/indi-mob.png');
        }
    }
}