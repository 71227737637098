.entries {
    padding:70px 0 57px;
    &__content {
        margin-top:45px;
        @include clearfix();
    }
    &__box {
        float:left;
        width:calc(20% - 38.4px);
        box-sizing: border-box;
        margin-right:48px;
        text-decoration:none;
        &:nth-child(5n+5) {
            margin-right:0;
        }
        &:hover {
            .entries__photo {
                opacity:0.8;
                span {
                    left:20px;
                }
            }
        }
    }
    &__photo {
        position:relative;
        @include transition;
        span {
            @include transition;
            position: absolute;
            left:-20px;
            bottom:18px;
            width:40px;
            height:5px;
            background-color:$black;
        }
        img {
            display:block;
            width:100%;
        }
    }
    &__title {
        font-size: calc(8px + 0.45vw);
        color:$white;
        position:absolute;
        bottom:45px;
        left:20px;
        padding-right: 20px;
    }
    &__link {
        font-family: $second-font;
        font-style:italic;
        font-size:16px;
        padding:20px 0;
        text-align:center;
        display:block;
        color:$grey4;
    }
}

@media (max-width: 992px) {
    .entries {
        padding-bottom:25px;
        &__box {
            width:calc(50% - 10px);
            margin-right:20px;
            &:nth-child(even) {
                margin-right:0;
                transition-delay: 0.1s;
            }
            &:nth-child(5n+5) {
                margin-right:20px;
            }
        }
        &__photo {
            span {
                left:-16px;
                bottom:8px;
                width:32px;
            }
        }
        &__title {
            font-size:16px;
        }
        &__link {
            padding:10px 0;
            font-size:12px;
        }
    }
}

@media (min-width : 992px) {
    .entries {
        &__box {
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
            &:nth-child(3) {
                transition-delay: 0.2s;
            }
            &:nth-child(4) {
                transition-delay: 0.3s;
            }
            &:nth-child(5) {
                transition-delay: 0.4s;
            }
            &:nth-child(7) {
                transition-delay: 0.1s;
            }
            &:nth-child(8) {
                transition-delay: 0.2s;
            }
            &:nth-child(9) {
                transition-delay: 0.3s;
            }
            &:nth-child(10) {
                transition-delay: 0.4s;
            }
        }
    }
}