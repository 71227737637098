.info {
    position:relative;
    padding:90px 30px;
    &__boxes {
        @include clearfix();
        margin-top:80px;
        position:relative;
        z-index: 101;
    }
    &__box {
        width:33.3%;
        color: $black;
        font-size:36px;
        float:left;
        span {
            font-family: $second-font;
            font-size: 16px;
            margin-top: 11px;
            display:block;
            max-width:300px;
            line-height:22px;
        }
        &.first {
            i {
                background-image:url('/assets/images/info-icon_3.png');
                background-repeat: no-repeat;
                background-position: center;
                display:block;
                width:85px;
                height:85px;
            }
        }
        &.second {
            i {
                background-image:url('/assets/images/info-icon_2.png');
                background-repeat: no-repeat;
                background-position: center;
                display:block;
                width:85px;
                height:85px;
            }
        }
        &.third {
            i {
                background-image:url('/assets/images/info-icon_1.png');
                background-repeat: no-repeat;
                background-position: center;
                display:block;
                width:85px;
                height:85px;
            }
        }
    }
    &__text {
        font-size:24px;
        color:$black;
        text-align:center;
        font-family: $second-font;
        margin-top:10px;
        &--small{
            font-size:20px;
        }
    }
}

.skewed-info {
    transform: rotate(-2deg) skew(-2deg);
    width: 100%;
    position: absolute;
    bottom: -45px;
    height: 90px;
    left: 0;
    z-index: 100;
    background-color: #fff;
}

@media (max-width: 992px) {
    .info {
        padding:16px 0 0 0;
        &__boxes {
            margin-top:38px;
        }
        &__box {
            margin-bottom:64px;
            max-width: 400px;
            margin-bottom: 64px;
            width: 400px;
            clear: both;
            margin: 0 auto 50px;
            overflow: hidden;
            float: none;
            &.first, &.second, &.third {
                i {
                    float:left;
                    width:50px;
                    height:50px;
                    background-size:100%;
                }
            }
            &-name {
                margin-top:10px;
                margin-left:15px;
                font-size:24px;
                float:left;
                max-width: calc(100vw - 140px);
                span {
                    font-size:14px;
                }
            }

        }
        &__text {
            margin-top:18px;
            font-size:14px;
        }
        .section {
            &__title {
                margin-top:20px;
            }
        }
    }
}


@media (min-width : 992px) {
    .info {
        &__box {
            &:nth-child(1) {
                transition-delay: 0.3s;
            }
            &:nth-child(2) {
                transition-delay: 0.6s;
            }
            &:nth-child(3) {
                transition-delay: 0.9s;
            }
        }
    }
}