.article-banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width:100%;
    height:540px;
    position:relative;
    &__slogan {
        max-width:520px;
        margin-bottom: 4px;
        color: #fff;
        line-height: 55px;
    }
    &__text {
        position:absolute;
        width:50%;
        bottom:48px;
        right:10px;
        font-size:36px;
        color:$white;
        span {
            background-color: $gold;
            padding: 2px 40px 5px 20px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }
        p {
            display:inline-block;
            background-color:$gold;
            // padding:0px 30px 4px 23px;
            padding:0px 30px 4px 23px;
            margin-bottom:5px;
        }
        a {
            font-size:24px;
            color:$white;
            display:inline-block;
            background-color:$black;
            padding:11px 40px 13px 20px;
            margin-bottom:5px;
            box-sizing: border-box;
            text-decoration: none;
            margin-top:37px;
            position:relative;
            i {
                background-image:url('/assets/images/arrow-right.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center right;
                width:13px;
                height:23px;
                display:inline-block;
                position:absolute;
                transform: translateY(-50%);
                top: 50%;
                right:15px;
            }
        }
    } 
}

@media (max-width: 992px) {
    .article-banner {
        min-height:100vh;
        &__slogan {
            line-height:32px;
            margin-bottom:2px;
        }
        &__text {
            width:calc(100% - 32px);
            left:16px;
            font-size:18px;
            bottom:70px;
            span {
                padding:4px 30px 4px 7px;
                margin-bottom:2px;
            }
            a {
                font-size: 15px;
                padding-left:7px;
                padding:11px 30px 13px 7px;
                i {
                    width:7px;
                    height:11px;
                }
            }
        }
        &__more {
            position:absolute;
            bottom:24px;
            left:50%;
            height:21px;
            width:50px;
            transform: translateX(-50%);
            &:hover {
                cursor:pointer;
            }
            background-image:url('/assets/images/indi-mob.png');
        }
    }
}