.article-info {
    position:relative;
    padding:22px 0 10px; 
    &__title {
        font-size:36px;
        color:$gold;
        margin:0;
        font-family: 'LinoType';
        background-image: url('/assets/images/article-title-bg.png');
        background-repeat: repeat-x;
        background-position: center;
        background-size: contain;
        span {
            display:inline-block;
            background-color:$white;
            padding-right:10px;
            font-weight:300;
        }
    }
    &__photo {
        margin:90px 0 10px;
        img {
            width:100%;
        }
    }
    &__area {
        position: relative;
        z-index: 101;
        font-family: $second-font;
        font-size:24px;
        
        p {
          font-size: 18px !important;
          line-height:1.8em !important;
        }
        
        a {
            color:$grey4;
            text-decoration:none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.skewed-article-info {
    transform: rotate(-2deg) skew(-2deg);
    width: 100%;
    position: absolute;
    bottom:-50px;
    height: 90px;
    left: 0;
    z-index: 100;
    background-color: #fff;
}

@media (max-width: 992px) {
    .article-info {
        padding-top:16px;
        &__title {
            font-size:20px;
        }
        p {
            font-size:14px;
        }
    }
}