.clearfix {
    display: block;
    clear: both;
}

.layout, .layout-wider {
    padding:0 16px;
    width:100%;   
    @include clearfix;
    box-sizing: border-box;
}
  
@media (min-width:1300px) {
    .layout {
        width:100%;
        max-width: 1280px;
        margin: 0 auto;
    }
}


@media (min-width: 992px) and (max-width: 1280px) {
  
  .banner, .pre-footer, .footer,
  .layout, .layout-wider {
    padding-left:45px !important;
    padding-right:45px !important;
    box-sizing: border-box;
  }
  
  
  
}