.article {
    .products {
        .skewed-products {
            display:none;
        }
    } 
    &-photo {
        position:relative;
        img {
            width:100%;
        }
    }
    &-ps {
        border: 1px solid #af8700;
        font-size: 18px !important;
        line-height:1.8em !important;
        font-weight: 300;
        color: #ffffff;
        margin: 0 0 30px 0;
        background-color: #af8700;
        padding:20px 10px 20px 0;
        font-family: $second-font;
        position: relative;
        z-index: 1000;
        i {
            font-family: $main-font;
            font-size: 36px;
            float: left;
            line-height: 36px;
            font-style: normal;
            border-right: 1px solid #ffffff;
            padding-right: 16px;
            height: 45px;
            margin:6px 23px 15px;
        }
        a {
            color: $white;
        }
        &--green{
            background-color: #498692;
            border: 1px solid #498692;
        }
    }
    &-template {
        font-size:24px;
        font-family: $second-font;
        margin-bottom:90px;
        .with-border p {
            padding:28px 0 32px;
            border-top:3px solid $gold;
            border-bottom:3px solid $gold;
            color:$grey4;
            font-size: 18px;
            line-height: 1.8em;
        }
        .with-square p {
            font-size:30px;
            padding:31px 5px 29px 22px;
            border:3px solid $gold;
            color:$grey4;
            line-height: 34px;
        }
        .with-header p {
            text-align: center;
            font-family: $main-font;
            font-size:36px;
            padding:10px 0 0;
        }
    }
}
@media (max-width: 992px) {
    .article {
        &-template {
          margin-bottom:20px;
            .with-border p {
                font-size:16px;
                padding:20px;
                line-height:20px;
            }
            .with-square p {
                font-size:16px;
                padding:20px;
                line-height:20px;
            }
            .with-header p {
                text-align: left;
                font-size:16px;
                padding:0;
            }
        }
        
        &-photo {
          background-repeat: no-repeat;
          min-height: 35vh;
          background-position: center;
          background-size: cover;
          
          img {
            display: none;
          }
          
        }
        
        &-ps {
            padding:24px 20px 20px 20px;
            i {
                margin:0;
                padding:0;
                border:0;
                float:none;
                display:block;
                margin-bottom:15px;
            }
            span {
                display:block;
                padding-top:15px;
                border-top:1px solid $white;
                border-left:0;
                font-size:14px;
            }
        }
    }
}