$skin30:#8f9b1e;
$skin45:#88212f;
$skin40:#b46311;
$skin50:#795d96;
$lipidro:#af8700;
$skinmixed:#699882;
$skinimper:#458694;
$rosacal:#ba8d94;
$hydrative: #23719f;


.lipidro {
    .icon-face {
        background-image:url('/assets/images/infographic/lipidro/face.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$lipidro;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$lipidro;
    }
    .graph-gold {
        border-color:$lipidro;
        color:$lipidro;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-two .border {
        border-color:$lipidro;
    }
}


.skin30 {
    .icon-face {
        background-image:url('/assets/images/infographic/skin30/face30.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$skin30;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$skin30;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$skin30;
        color:$skin30;
    }
}

.skin45 {
    .icon-face {
        background-image:url('/assets/images/infographic/skin45/face45.png');;
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$skin45;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$skin45;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$skin45;
        color:$skin45;
    }
}

.skin50 {
    .icon-face {
        background-image:url('/assets/images/infographic/skin50/face50.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$skin50;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$skin50;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$skin50;
        color:$skin50;
    }
}

.skinmixed {
    .icon-face {
        background-image:url('/assets/images/infographic/skinmixed/facemixed.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$skinmixed;
    }
    .graph .lipidro {
        color:$lipidro;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$skinmixed;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$skinmixed;
        color:$skinmixed;
    }
}

.skinimper {
    .icon-face {
        background-image:url('/assets/images/infographic/skinimper/faceimper.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$skinimper;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$skinimper;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$skinimper;
        color:$skinimper;
    }
}

.skin40 {
    .icon-face {
        background-image:url('/assets/images/infographic/skin40/face40.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$skin40;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$skin40;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$skin40;
        color:$skin40;
    }
}

.rosacal {
    .icon-face {
        background-image:url('/assets/images/infographic/rosacal/facerosacal.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$rosacal;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$rosacal;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$rosacal;
        color:$rosacal;
    }
}

.hydrative {
    .icon-face {
        background-image:url('/assets/images/infographic/hydrativ/facehydrativ.png');
    }
    .graph-top__logo-slogan,
    .graph-item-info,
    .graph-item-info2,
    .graph-item-info4,
    .graph em {
        color:$hydrative;
    }
    .graph-two ul li i:before,
    .graph-title span {
        background-color:$hydrative;
    }
    .graph-gold span,
    .graph-gold i,
    .graph-gold {
        border-color:$hydrative;
        color:$hydrative;
    }
    .graph-three-text small {
        background-color:$hydrative;
        color:$white;
    }
}