.pre-footer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width:100%;
    &__content {
        @include clearfix();
        padding:26px 0;
    }
    &__link {
        font-size:24px;
        color:$white;
        background-color:$black;
        display:inline-block;
        text-decoration:none;
        padding:11px 40px 13px 20px;
        float:right;
        margin-top:15px;
        box-sizing: border-box;
        position:relative;
        outline:none;
        border:0;
        i {
            position:absolute;
            top:50%;
            transform: translateY(-50%);
            right:10px;
        }
    }
    &__text {
        font-size:36px;
        color:$white;
        float:left;
        width:calc(100% - 560px);
        margin-left:25px;
    }
    .bulb {
        float:left;
        margin-top:15px;
    }
}
@media (max-width: 992px) {
    .pre-footer {
        &__text {
            width: calc(100% - 90px);
            font-size:18px;
        }
        .bulb {
            margin-top:-10px;
        }
        &__link {
            margin-left:25px;
            float:left;
            font-size:16px;
            padding:11px 30px 13px 7px;
            margin-top: 25px;
        }
    }

}
