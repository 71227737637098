.package {
    padding:135px 0 0;
    z-index:101;
    background-color:$grey2;
    height:1000px;
    position:relative;
    &__content {
        margin-top:85px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include clearfix();
    }
    &__item {
        width:33.3%;
        float:left;
        text-align:center;
        display:block;
        position:relative;
    }
    &__day {
        margin-bottom:20px;
        text-align: center;
        font-size:36px;
        color:$grey3;
        i {
            margin:0 auto 27px;
        }
        &.first {
            i {
                background-image:url('/assets/images/info-icon_3.png');
                background-repeat: no-repeat;
                background-position: center;
                display:block;
                width:85px;
                height:85px;
            }
        }
        &.second {
            i {
                background-image:url('/assets/images/info-icon_2.png');
                background-repeat: no-repeat;
                background-position: center;
                display:block;
                width:85px;
                height:85px;
            }
        }
        &.third {
            i {
                background-image:url('/assets/images/info-icon_1.png');
                background-repeat: no-repeat;
                background-position: center;
                display:block;
                width:85px;
                height:85px;
            }
        }
    }
    &__link {
        font-size:24px;
        color:$white;
        background-color:$black;
        display:inline-block;
        text-decoration:none;
        padding:11px 30px 11px 30px;
        margin-top:15px;
        box-sizing: border-box;
        position:relative;
        width:190px;
        i {
            position:absolute;
            top:50%;
            transform: translateY(-50%);
            right:30px;
        }
    }
    &__box {
        text-decoration:none;
        margin-bottom:20px;
        span {
            font-size:18px;
            color:$grey4;
            display: block;
            margin:0 auto;
            min-height:135px;
            padding:0 10px;
            margin-top:20px;
        }
        &:hover {
            .package__title {
                color:$black;
            }
            .package__hover {
                opacity:1;
            }
        }
    }
    &__title {
        @include transition();
        font-size:30px;
        color: $grey3;
        margin:25px 0 0;
    }
    &__hover {
        @include transition;
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        filter: brightness(70%) contrast(2);
        opacity:0;
        max-height: 280px;
    }
    &__photo {
        position:relative;
        &__img {
            position:relative;
            max-height: 280px;  
        }
    }
    .skewed-package {
        transform: rotate(-2deg) skew(-2deg);
        width: 100%;
        position: absolute;
        top: -45px;
        height: 90px;
        left: 0;
        z-index: 100;
        background-color: #fff;
    }
}

@media (max-width: 992px) {
    .package {
        height:auto;
        padding-top:85px;
        padding-bottom:20px;
        &__content {
            margin-top:40px;
        }
        &__day {
            font-size:18px;
            margin-bottom:0;
            &.first, &.second, &.third {
                i {
                    display:none;
                }
            }
        }
        &__item {
            width:100%;
            margin-bottom:60px;
        }
        &__box {
            span {
                float:none;
                padding:0;
                font-size:16px;
                margin-top:5px;
                min-height:auto;
                p {
                    margin:0;
                    padding:0;
                }
            }
        }
        &__photo {
            float:left;
            position:relative;
            width:100%;
            img {
                width:auto;
            }
        }
        &__title {
            float:left;
            width:100%;
            text-align:center;
            font-size:20px;
        }
        &__link {
            width:auto;
            font-size: 15px;
            padding-left:7px;
            padding:16px 50px 16px 35px;
            i {
                width:7px;
                height:11px;
            }
        }
    }
}