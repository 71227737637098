@font-face { 
    font-family: 'LinoType';
    src: url('../../../assets/fonts/3609E6_0_0.eot'); /* IE9 Compat Modes */
    src: url('../../../assets/fonts/3609E6_0_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../../../assets/fonts/3609E6_0_0.woff2') format('woff2'), /* Super Modern Browsers */
        url('../../../assets/fonts/3609E6_0_0.woff') format('woff'), /* Pretty Modern Browsers */
        url('../../../assets/fonts/3609E6_0_0.ttf')  format('truetype') /* Safari, Android, iOS */
        //  url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}


body {
    font-family: 'LinoType';
}

html, body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.section__title {
    font-size:36px;
    color:#000000;
    margin:0;
    text-align:center;
    font-weight:normal;
    position: relative;
    z-index: 101;

}

@media (max-width: 992px) {
    .section__title {
        font-size:18px;
    }
}

.cookie-block{
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    padding: 15px 30px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    background: #f7f7f7;
    box-sizing: border-box;
    p{
        margin: 0;
        text-align: center;
        a{
            text-decoration: underline;
            color: #000000;
        }
    }
    .close-cookie{
        background: #000;
        color: #fff;
        text-decoration: none;
        padding: 5px 10px;
        display: inline-block;
    }

}