.products {
    position:relative;
    padding:45px 0 0;
    z-index:101;
    &__content {
        margin-top:40px;
        @include clearfix();
    }
    &__box {
        width:33.3%;
        float:left;
        text-align:center;
        display:block;
        text-decoration:none;
        margin-bottom:20px;
        position:relative;
        span {
            font-size: 15px;
            line-height:140%;
            color:$grey4;
            display: block;
            margin:0 auto;
            min-height:135px;
            padding:0 10px;
            margin-top:20px;
        }
        &:hover {
            .products__title {
                color:$black;
            }
            .products__hover {
                opacity:1;
            }
        }
    }
    &__title {
        @include transition();
        font-size:30px;
        color: $grey3;
        margin:25px 0 0;
    }
    &__hover {
        @include transition;
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        filter: brightness(80%) contrast(1);
        opacity:0;
    }
    .skewed-products {
        transform: rotate(-2deg) skew(-2deg);
        width: 100%;
        position: absolute;
        top: -45px;
        height: 90px;
        left: 0;
        z-index: 100;
        background-color: #fff;
    }
}

@media (max-width: 992px) {
    .products {
        &__box {
            margin-bottom: 40px;
            width:100%;
            span {
                float:left;
                padding:0;
                font-family: "Open Sans", sans-serif;
                font-size:13px;
                margin-top:15px;
                min-height: 15px;
            }
        }
        &__photo {
            float:left;
            position:relative;
            width:30%;
            img {
                width:90%;
            }
        }
        &__title {
            float:left;
            width:70%;
            text-align:left;
            font-size:16px;
        }
    }
}


@media (min-width : 992px) {
    .products {
        &__box {
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
            &:nth-child(3) {
                transition-delay: 0.2s;
            }
            &:nth-child(5) {
                transition-delay: 0.1s;
            }
            &:nth-child(6) {
                transition-delay: 0.2s;
            }
            &:nth-child(8) {
                transition-delay: 0.1s;
            }
            &:nth-child(9) {
                transition-delay: 0.2s;
            }
        }
    }
}